import React from 'react';
import styled from 'styled-components';
import {Button, Result, Space} from 'antd';
import * as AppActions from '../../AppActions';
import Config from '../../../data.json';

const Empty = (props) => {
  return (
    <Wrapper>
      <Result
        icon={<img src={Config.emptyImage} alt="empty" />}
        title={Config.emptyTitle}
        subTitle={Config.emptySubtitle}
        extra={
          <Space direction="vertical">
            <Button onClick={async () => await AppActions.navigate('/')}>
              {Config.emptyButtonText}
            </Button>
          </Space>
        }
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & img {
    width: 150px;
  }
`;

export default Empty;
