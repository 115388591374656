import React from 'react';
import styled from 'styled-components';
import {Helmet} from 'react-helmet';
import * as Jstorage from 'rev.sdk.js/Actions/JStorage';
import RichTextPreview from 'rev.sdk.js/Components/RichTextPreview';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import BrowserHeader from '../../Components/BrowseHeader';
import ProductGrid from '../ProductList/ProductGrid';
import Config from '../../../data.json';

function SalesEvent(props) {
  const {salesEvent} = props.pageContext;
  const [products, setProducts] = React.useState(null);
  const [article, setArticle] = React.useState(null);

  React.useEffect(() => {
    async function fetchProducts() {
      if (!salesEvent.products || salesEvent.products.length === 0) {
        return null;
      }

      let resp = await Jstorage.fetchAllDocuments(
        'product',
        {_id: {$in: salesEvent.products.map((id) => ({$oid: id}))}},
        null,
        {name: 1, price: 1, original_price: 1, images: 1},
        {anonymous: true},
      );
      let products = [];

      for (let i in salesEvent.products) {
        let product = resp.find((p) => p.id === salesEvent.products[i]);
        products.push(product);
      }

      return products;
    }

    async function fetchArticle() {
      if (!salesEvent.article) {
        return null;
      }

      let article = await Jstorage.fetchOneDocument(
        'Article_Default',
        {
          _id: {$oid: salesEvent.article},
        },
        null,
        {anonymous: true},
      );

      return article;
    }

    async function fetchInitialData() {
      AppActions.setLoading(true);
      try {
        let values = await Promise.all([fetchProducts(), fetchArticle()]);
        setProducts(values[0]);
        setArticle(values[1]);
      } catch (err) {
        console.warn(err);
      }
      AppActions.setLoading(false);
    }

    fetchInitialData();
  }, [salesEvent]);

  return (
    <>
      <Helmet>
        <title>{Config.site_name}</title>
        <meta property="og:site_name" content={Config.site_name} />
        <meta property="og:title" content={salesEvent.og_title} />
        <meta property="og:image" content={salesEvent.og_image?.[0]} />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="314" />
        <meta property="og:description" content={salesEvent.og_description} />

        <meta name="title" content={Config.site_name} />
        <meta name="description" content={Config.description} />
        <meta name="image" content={Config.logo} />
      </Helmet>

      <Wrapper id="rev-SalesEventPage">
        <div className="container">
          <div className="browse-header">
            <BrowserHeader route="/sales-event" cat={salesEvent.name} />
          </div>
          <div className="article">
            <RichTextPreview content={article?.content} key={article?.id} />
          </div>
          <div className="products">
            <ProductGrid
              products={products || []}
              onItemClick={(item) =>
                AppActions.navigate(`/product?id=${item.id}`, {loading: true})
              }
              prefixPath="/sales-event"
            />
          </div>
        </div>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  padding-top: var(--topNavBarHeight);

  & > .container {
    max-width: var(--contentMaxWidth);
    width: 100%;
    margin: 0 auto;

    & > .article {
      padding: 10px 0px;
    }
  }
`;

export default withPageEntry(SalesEvent);
